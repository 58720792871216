

























import { Component, Vue, Prop } from 'vue-property-decorator'
import './styles.scss'

@Component({
  name: 'YandexMic',
})
export default class YandexMic extends Vue {
  /**
   * Lang for custom screenshots
   */
  @Prop({
    type: String,
    required: true,
    default: 'ru',
  })
  readonly lang!: 'ru' | 'en';
}
